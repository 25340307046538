import React, { Component } from 'react'
import { navigate } from '@reach/router'
import Button from '@material-ui/core/Button'
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'
import AniLink from "gatsby-plugin-transition-link/AniLink"


class ItemCard extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let icon = "";
        if (this.props.viewType === 'Product') icon = 'product';
        if (this.props.viewType === 'System') icon = 'systems';

        return (
            <AniLink fade className="item-card" to={this.props.url}>
                <div className="item-card__image">
                    { this.props.imagePath === 'url(null?width=400)' 
                        ?
                        <div className="item-card__image-placeholder">
                            <IcomoonReact iconSet={iconSet} size={50} icon={icon} />
                        </div>
                        :
                        <div className="" style={{backgroundImage: this.props.imagePath }}></div>
                    } 
                    
                </div>
                <div className="item-card__content">
                    <div className="item-card__title">{this.props.title}</div>
                    <div className="app-btn">
                        <IcomoonReact iconSet={iconSet} size={18} icon={icon} className="app-btn__icon"/> 
                        <div className="app-btn__text">View {this.props.viewType}</div>
                    </div>
                </div>
            </AniLink>
        );
    }
}

export default ItemCard;
